import {WidgetSpinner} from "./widgetspinner";
import {logger} from "@otto-ec/global-resources/debug";
import {} from "./main";


const log = logger("ft3.widgetspinner");

log.info("FT3 main script loaded")
/*                                                    */
window.o_global.eventQBus.emitModuleLoaded("ft3.promo.widgetspinner");

export function main() {
    const widgetSpinner = new WidgetSpinner();
    window.o_global.eventQBus.on("ft3.promo-widgetspinner.init", () => {
        log.info("FT3 on: ft3.promo-widgetspinner.init");
        widgetSpinner.initFooterSlots();
    });

    window.o_global.eventQBus.on("reptile.tilelist-cache.ignored", () => {
            log.info("Reptile on: reptile.tilelist-cache.ignored");
            widgetSpinner.initTilelistWidgets();
            markTilelistLoadType("loaded");
        }
    )

    window.o_global.eventQBus.on("reptile.tilelist-cache.used", () => {
            log.info("Reptile on: reptile.tilelist-cache.used");
            widgetSpinner.initTilelistJavaScript();
            markTilelistLoadType("cached");
        }
    )
}

main();

/**
 *
 */
function markTilelistLoadType(value: string): void {
    document.querySelector(".promo-widget-container")?.setAttribute("data-tilelist-load-type", value);
}


