import {WidgetFetcher, WidgetResponse} from "./widget-fetcher";
import {AsyncWidgetData, FooterSlotWidget, TileSlotWidget} from "./types";
import {logger} from "@otto-ec/global-resources/debug";
import {executeInlineScripts} from "@otto-ec/global-resources/hardcore";

const log = logger("ft3.widgetspinner");
const TILE_PREFIX = "tile";
const FOOTER_PREFIX = "footer";

export class WidgetSpinner {

    private tilelistWasAlreadyInitializedAtLeastOnce = false;
    private widgetFetcher: WidgetFetcher = new WidgetFetcher();

    private getOrParseAsyncWidgetDataWithSsid(callSource: string): { asyncWidgetData: AsyncWidgetData, ssid: string } {
        const widgetContainer = document.querySelector(".promo-widget-container");
        if (!widgetContainer || !widgetContainer.getAttribute("data-async-widgets")) {
            throw Error(`Widget container could not be found while initializing ${callSource} widgets`);
        }
        return {
            asyncWidgetData: JSON.parse(widgetContainer.getAttribute("data-async-widgets")!),
            ssid: widgetContainer.getAttribute("data-ssid")!
        };
    }

    initFooterSlots(): Promise<void[]> {
        const asyncWidgetDataWithSsid = this.getOrParseAsyncWidgetDataWithSsid("footer");
        return Promise.all(this.loadFooterWidgets(asyncWidgetDataWithSsid.asyncWidgetData.footerSlotWidgets, asyncWidgetDataWithSsid.ssid));
    }

    private loadFooterWidgets(footerSlotWidgets: FooterSlotWidget[], ssid: string): Promise<void>[] {
        const footerSlotElement: Element | null = document.querySelector(".reptile_mas-footer-slot");
        if (footerSlotElement == null) {
            log.warn("Reptile mas footer slot is not available");
            return [Promise.reject("Reptile mas footer slot is not available")];
        }
        if (footerSlotElement.innerHTML.trim().length > 0) {
            /**
 *
 *
 *
 */
            log.info("Footer slot is already loaded. Seems to be a filter selection.");
            return [];
        }
        footerSlotElement.innerHTML = "";
        let footerSlotPosition = 1;
        return footerSlotWidgets.map((asyncWidget: FooterSlotWidget): Promise<void> => {
            const widgetContainer = this.createWidgetContainer(asyncWidget.widgetType, FOOTER_PREFIX, "-1", "-1");
            widgetContainer.setAttribute("data-footer-slot", `${footerSlotPosition}`);
            footerSlotPosition++;
            footerSlotElement.appendChild(widgetContainer);
            return this.widgetFetcher.fetch(asyncWidget, ssid)
                .then((widgetResponse: WidgetResponse<FooterSlotWidget> | undefined) => {
                    if (widgetResponse) {
                        widgetContainer.appendChild(widgetResponse.response);
                        this.initializeInlineScripts(widgetContainer);
                    }
                })
                .catch((e) => log.warn("Unexpected error while fetching async widget", e))
        });
    }

    async initTilelistWidgets(): Promise<void> {
        const tilelistResponses = await this.loadTilelistWidgets();
        this.appendWidgetsToTilelistSlots(tilelistResponses);
        this.initializeLever();
    }

    private async loadTilelistWidgets() {
        let asyncWidgetDataWithSsid: { asyncWidgetData: AsyncWidgetData, ssid: string };
        try {
            asyncWidgetDataWithSsid = this.getOrParseAsyncWidgetDataWithSsid("tilelist");
            /*                                                         */
        } catch (e) {
            /**
 *
 *
 *
 *
 */
            if (this.tilelistWasAlreadyInitializedAtLeastOnce) {
                return Promise.resolve([]);
            } else {
                throw Error("Widget data are not available - this should not happen: PLEASE INVESTIGATE!");
            }
        }
        this.tilelistWasAlreadyInitializedAtLeastOnce = true;
        const fetchPromises = asyncWidgetDataWithSsid.asyncWidgetData.tileSlotWidgets.map(value => this.widgetFetcher.fetch(value, asyncWidgetDataWithSsid.ssid));
        const responses: (WidgetResponse<TileSlotWidget> | undefined)[] = await Promise.all(fetchPromises);
        return responses.filter(response => !!response) as WidgetResponse<TileSlotWidget>[];
    }

    private appendWidgetsToTilelistSlots(tilelistResponses: WidgetResponse<TileSlotWidget>[]): void {
        const prefetchedLeverContainer = document.querySelector(".promo-widget-prefetch[data-widget-type='PRODUCTLIST_LEVER'], .promo-widget-prefetch[data-widget-type='SEARCH_RESULT_PAGE_LEVER']")
        const secondSda = document.querySelector("#sda-advert-2");
        const masSlotElements = document.querySelectorAll(".reptile_mas-list-slot")
        const widgetTypeToFeatureIndex: { [key: string]: number } = {};
        masSlotElements.forEach((element, index) => {
            element.innerHTML = '';
            const widgetResponse = tilelistResponses.find(response => response.widget.targetSlotIndex === index);
            if (widgetResponse) {
                this.appendGenericWidget(widgetResponse, widgetTypeToFeatureIndex, element);
            } else if (index === 1 && !!secondSda) {
                this.appendLegacySDAWidget(widgetTypeToFeatureIndex, element, secondSda);
            } else if (prefetchedLeverContainer != null) {
                this.appendLeverWidget(prefetchedLeverContainer, widgetTypeToFeatureIndex, element);
            }
        })
    }

    private appendLeverWidget(prefetchedLeverContainer: Element, widgetTypeToFeatureIndex: { [p: string]: number }, element: Element) {
        const widgetType = prefetchedLeverContainer.getAttribute("data-widget-type")!;
        const featureIndex = this.getNextFeatureIndex(widgetType, widgetTypeToFeatureIndex);
        this.setTileSlotDataAttributes(element, widgetType, featureIndex);
        element.innerHTML = (prefetchedLeverContainer.cloneNode(true) as HTMLElement).innerHTML
    }

    private appendLegacySDAWidget(widgetTypeToFeatureIndex: { [p: string]: number }, element: Element, secondSda: Element) {
        const featureIndex = this.getNextFeatureIndex("SDA_LEGACY", widgetTypeToFeatureIndex);
        this.setTileSlotDataAttributes(element, "SDA_LEGACY", featureIndex);
        element.appendChild(secondSda.cloneNode(true));
        secondSda.remove();
    }

    private appendGenericWidget(widgetResponse: WidgetResponse<TileSlotWidget>, widgetTypeToFeatureIndex: { [p: string]: number }, element: Element) {
        const featureIndex = this.getNextFeatureIndex(widgetResponse.widget.widgetType, widgetTypeToFeatureIndex);
        this.setTileSlotDataAttributes(element, widgetResponse.widget.widgetType, featureIndex);
        element.appendChild(widgetResponse.response);
        this.initializeInlineScripts(element as HTMLElement);
    }

    private setTileSlotDataAttributes(element: Element, widgetType: string, featureIndex: string) {
        element.setAttribute("data-widget-type", widgetType);
        element.setAttribute("data-feature-index", featureIndex);
        element.classList.add(`promo-widget-${TILE_PREFIX}-slot`);
    }

    initTilelistJavaScript(): void {
        this.tilelistWasAlreadyInitializedAtLeastOnce = true;
        const tileSlotElements = document.querySelectorAll('.promo-widget-tile-slot');
        tileSlotElements.forEach(element => this.initializeInlineScripts(element as HTMLElement));
        this.initializeLever();
    }

    private initializeLever() {
        const lever = document.querySelector(".promo-widget-prefetch[data-widget-type='PRODUCTLIST_LEVER'], .promo-widget-prefetch[data-widget-type='SEARCH_RESULT_PAGE_LEVER']");
        if (lever != null) {
            window.o_global.eventQBus.emit("ft9.benefit.init");
        }
    }

    private createWidgetContainer(widgetType: string, slotType: string, featureOrder: string, featureIndex: string): HTMLElement {
        const widgetContainer = document.createElement("div");
        widgetContainer.setAttribute("data-widget-type", widgetType);
        widgetContainer.setAttribute("data-feature-index", featureIndex);
        widgetContainer.classList.add(`promo-widget-${slotType}-slot`);
        return widgetContainer;
    }

    private initializeInlineScripts(container: HTMLElement) {
        if (container.querySelectorAll("script").length > 0) {
            log.debug(`executeInlineScript for widget: ${container.getAttribute("data-widget-type")}`);
            executeInlineScripts(container);
        }
        window.invokePreload.processPreloadsOnElement(container);
    }

    private getLastTopSlotFeatureOrder() {
        const prefetchContainers = Array.from(document.querySelectorAll(".promo-widget-prefetch"))
        const prefetchFeatureOrders = prefetchContainers.map(node => parseInt(node.getAttribute('data-feature-order')!))
        return Math.max(...prefetchFeatureOrders)
    }

    private getNextFeatureIndex(widgetType: string, widgetTypeToFeatureIndex: { [key: string]: number }): string {
        if (widgetTypeToFeatureIndex[widgetType] === undefined) {
            widgetTypeToFeatureIndex[widgetType] = this.getLastTopSlotFeatureIndex(widgetType) + 1;
        } else {
            widgetTypeToFeatureIndex[widgetType] += 1;
        }
        return widgetTypeToFeatureIndex[widgetType].toString();
    }

    private getLastTopSlotFeatureIndex(widgetType: string): number {
        let lastTopSlotFeatureIndex = 0;
        document.querySelectorAll('[data-widget-type="' + widgetType + '"]').forEach((element) => {
            const featureIndex = parseInt(element.getAttribute("data-feature-index") || "0");
            if (featureIndex > lastTopSlotFeatureIndex) {
                lastTopSlotFeatureIndex = featureIndex;
            }
        });
        return lastTopSlotFeatureIndex;
    }
}