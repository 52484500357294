import {AsyncWidget} from "./types";

export class WidgetFetcher {

    fetch<T extends AsyncWidget>(asyncWidget: T, ssid: string): Promise<WidgetResponse<T> | undefined> {
        const requestOptions: RequestInit = {
            headers: new Headers({'X-Origin-Ssid': ssid, "X-Requested-With": "XMLHttpRequest"}),
            method: 'GET',
        };
        let timeoutId: NodeJS.Timeout | null = null;
        if (typeof AbortController !== "undefined") {
            const controller = new AbortController();
            timeoutId = setTimeout(() => controller.abort(), 7500);
            requestOptions["signal"] = controller.signal;
        }
        return fetch(asyncWidget.urlPath, requestOptions)
            .then((response: Response): Promise<string> => {
                if (response.status === 200) {
                    return response.text();
                } else {
                    throw new Error("No content for widget");
                }
            })
            .then((html: string): WidgetResponse<T> => {
                return {
                    response: window.o_util.dom.stringToDocumentFragment(html),
                    widget: asyncWidget
                };
            })
            .catch(() => undefined)
            .then(widget => {
                if (timeoutId) {
                    clearTimeout(timeoutId);
                }
                return widget;
            })
    }
}

export interface WidgetResponse<T> {
    response: DocumentFragment,
    widget: T
}